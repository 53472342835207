.suspended {
    color: red !important;
    background-color: yellow;
}

.active {
    color: #32A6D5 !important;
    background-color: transparent;
}

.closed {
    color: red !important;
    background-color: lightgray;
}
