.ant-table-wrapper {
    display: flex;
    flex: 1 1 100% !important;
}

.ant-table {
    border-radius: 16px !important;
    cursor:pointer
}

.ant-table-container, .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 16px;
}

td.ant-table-column-sort {
    background-color: transparent;
}

.ant-table-container, .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 16px;
}

.ant-modal-content {
    border-radius: 14px;
}

.ant-modal-header {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
}

.ant-progress-text {
    font-family: Nunito;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #B0B9C6;
}

.ant-modal-footer {
    display: none;
}

.ant-picker.ant-picker-borderless {
    width: 100%;
}

.events {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.events .ant-badge-status {
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    width: 10px;
    height: 10px;
}

.notes-month {
    font-size: 9px;
    text-align: center;
}

.notes-month section {
    font-size: 18px;
}

.ant-picker-cell .ant-picker-cell-inner {
    display: block;
}

.ant-modal-body {
    padding: 14px;
}

.ant-progress-bg {
    height: 4px !important;
}

.ant-progress-outer {
    height: 5px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.ant-progress-line {
    height: 14px !important;
}

.ant-picker-calendar .ant-picker-panel {
    border-top: none;
}

.ant-input-group-addon {
    background: transparent;
}

.ant-badge-status {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    flex-direction: row-reverse;
}
