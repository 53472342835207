.events {
    margin: 0;
    padding: 0;
    display:flex;
    flex-direction: row;
    list-style: none;
}
.events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.notes-month {
    font-size: 18px;
    text-align: center;
}
.notes-month section {
    font-size: 18px;
}
.ant-badge-status {

}
