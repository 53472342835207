.editor-wrapper {
    height: 100%;
    max-width: 90vh;
    font-family: Nunito;
}

.editor-content {
    min-height: 700px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    font-family: Nunito;
}

.editor-wrapper-faq {
    font-family: Nunito;
    height: 100%;
    padding: 10px;
    max-width: 90vh;
}

.editor-content-faq {
    font-family: Nunito;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
}

